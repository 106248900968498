import React from "react"
import styles from "./video-thumb.module.scss"
import ArrowSvg from "../svg/arrow"
import PlaySvg from "../svg/play"

const VideoThumb = ({
  title,
  description,
  videoUrl,
  previewImgUrl,
  lang,
  props,
}) => {
  return (
    <a href="#" onClick={props.openSelectedVideoFn} className={styles.video}>
      <div
        // onClick={openVideo(storingVideo)}
        aria-label="open Secure Storing and Sharing video"
        className={styles.video__preview}>
        <PlaySvg className={styles.video__previewPlay} />
        <img src={previewImgUrl} alt={title} />
      </div>
      <div className={styles.video__body}>
        <h5 className={styles.video__title}>
          {lang && (
            <i
              className={styles.video__flag}
              style={{
                backgroundImage: `url('/img/company/flags/${lang}-flag.png')`,
              }}
            />
          )}
          {title}
        </h5>
        <p className={styles.video__description}>{description}</p>
      </div>
      <div className={styles.video__foot}>
        <div className={styles.video__link} href={videoUrl}>
          Watch video
          <ArrowSvg />
        </div>
      </div>
    </a>
  )
}

export default VideoThumb
