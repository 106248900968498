import styles from "../../styles/videos.module.scss"
import React, { useState, useContext, useRef } from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import {
  VID_EVENTS,
  VID_INTERVIEWS,
  VID_PROD_DEMOS,
  VID_PROD_OVERVIEWS,
  VideosContentMap,
  VID_TECHNOLOGY,
} from "../../utils/constants"
import VideoThumb from "../../components/video-thumb/video-thumb"
import FsLightbox from "fslightbox-react"
import withAppContext from "../../contexts/app.context"
import { ShowAnnouncementContext } from "../../contexts/show-announcement.context"

const VideosPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fields: { category: { eq: "videos" } }
          frontmatter: { draft: { in: [false, null] } }
        }
        sort: { fields: fields___relativePath }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              draft
              subtype
              lang
              videoUrl
              previewImgUrl {
                publicURL
              }
              external
            }
          }
        }
      }
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "resources__center" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  const [activeTab, setActiveTab] = useState("events")

  const defaultVideo = ""
  const [videoLink, setVideoLink] = useState(defaultVideo)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const openVideo = (event, link, external) => {
    event.preventDefault()
    // console.log('video clicked ' + link + ' = ' + external)
    if (!external) {
      setVideoLink(link)
      setVideoOpened(!isVideoOpened)
    } else {
      window.open(link, "_blank")
    }
  }

  const videosTabs = useRef()

  const changeTab = tab => {
    setActiveTab(tab)
    if (window.innerWidth < 992) {
      console.log(getTabsTop())
      window.scrollTo({
        top: getTabsTop(),
        behavior: "smooth",
      })
    }
  }

  const getTabsTop = () => {
    if (showAnnouncement && window.innerWidth > 600) {
      return 160
    } else if (showAnnouncement && window.innerWidth < 600) {
      return 120
    } else if (window.innerWidth > 600) {
      return 160
    }
    return 100
  }

  let filteredVideos = queryResult.allMarkdownRemark.edges
  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  if (activeTab !== "all") {
    filteredVideos = filteredVideos.filter(edge =>
      VideosContentMap[activeTab].includes(edge.node.frontmatter.subtype)
    )
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/resources/videos"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />

      <div className={styles.videos}>
        <h1 className={styles.videos__header}>Videos</h1>
        <div
          ref={videosTabs}
          className={
            `${styles.videos__tabs} ` +
            (showAnnouncement ? styles.videos__tabsWithannouncement : "")
          }>
          <div
            aria-label="Events & Webinars Tab"
            role="button"
            onClick={() => changeTab(VID_EVENTS)}
            className={`${styles.videos__tab} ${
              activeTab === VID_EVENTS ? styles.videos__tabActive : ""
            }`}>
            Events & Webinars
          </div>
          <div
            aria-label="Interviews & Podcasts Tab"
            role="button"
            onClick={() => changeTab(VID_INTERVIEWS)}
            className={`${styles.videos__tab} ${
              activeTab === VID_INTERVIEWS ? styles.videos__tabActive : ""
            }`}>
            Interviews & Podcasts
          </div>
          <div
            ves-e2e-test="resource-center/podcast-tab"
            aria-label="Product Demos Tab"
            role="button"
            onClick={() => changeTab(VID_PROD_DEMOS)}
            className={`${styles.videos__tab} ${
              activeTab === VID_PROD_DEMOS ? styles.videos__tabActive : ""
            }`}>
            Product Demos
          </div>
          <div
            aria-label="Product Overviews Tab"
            role="button"
            onClick={() => changeTab(VID_PROD_OVERVIEWS)}
            className={`${styles.videos__tab} ${
              activeTab === VID_PROD_OVERVIEWS ? styles.videos__tabActive : ""
            }`}>
            Product Overviews
          </div>
          <div
            aria-label="Technology Series Tab"
            role="button"
            onClick={() => changeTab(VID_TECHNOLOGY)}
            className={`${styles.videos__tab} ${
              activeTab === VID_TECHNOLOGY ? styles.videos__tabActive : ""
            }`}>
            Technology Series
          </div>
        </div>

        <div className={`container ${styles.videos__wrapper}`}>
          <div className={styles.videos__list}>
            {filteredVideos.map(res => (
              <VideoThumb
                key={res.node.frontmatter.title}
                className={styles.videos__preview}
                title={res.node.frontmatter.title}
                description={res.node.frontmatter.description}
                lang={res.node.frontmatter.lang}
                videoUrl={res.node.frontmatter.videoUrl}
                previewImgUrl={res.node.frontmatter.previewImgUrl.publicURL}
                props={{
                  openSelectedVideoFn: e => {
                    openVideo(
                      e,
                      res.node.frontmatter.videoUrl,
                      res.node.frontmatter.external
                    )
                  },
                }}
              />
            ))}
          </div>
        </div>

        <FsLightbox
          toggler={isVideoOpened}
          sources={[
            <iframe
              src={videoLink}
              width="1920px"
              height="1080px"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />,
          ]}
        />
      </div>
    </Layout>
  )
}

export default withAppContext(VideosPage)
